import React, { useState } from 'react';
import {
	Box,
	Button,
	Form,
	FormField,
	MaskedInput,
	TextArea,
	TextInput,
	Heading,
	CheckBoxGroup
} from "grommet"
import Layout from "../components/layout"

const ContactPage = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [services, setServices] = useState([]);
	const [comments, setComments] = useState('');

	const formSuccess = () => {
		setName('');
		setEmail('');
		setServices([]);
		setComments('Message sent successfully!');
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await fetch("http://localhost:8080/form", {
				headers: {
					"Content-Type": "application/json"
				},
				method: "POST",
				mode: "cors",
				body: JSON.stringify({
					name: name,
					email: email,
					services: services,
					message: comments
				})
			}).then((response) => {
				console.log("Form Post Response: " + response.status);
				if(!response.ok) {
					throw Error();
				}
			});
			formSuccess();
		} catch (err) {
			console.log(err);
		}
	}

	return (
		<Layout pageTitle="Contact">
			<Heading>Contact</Heading>
			<Box fill align="center">
				<Box background="background-contrast" pad="medium" round="small" width="medium">
					<Form
					onReset={() => {
						setName('');
						setEmail('');
						setServices([]);
						setComments('');
					}}
					onSubmit={handleSubmit}
					>
					<FormField label="Name" name="name">
						<TextInput
						name="name"
						value={name}
						onChange={(event) => setName(event.target.value)}
						/>
					</FormField>
					<FormField label="Email" name="email" required>
						<MaskedInput
						name="email"
						mask={[
							{ regexp: /^[\w\-_.]+$/, placeholder: 'example' },
							{ fixed: '@' },
							{ regexp: /^[\w]+$/, placeholder: 'my' },
							{ fixed: '.' },
							{ regexp: /^[\w]+$/, placeholder: 'com' },
						]}
						value={email}
						onChange={(event) => setEmail(event.target.value)}
						/>
					</FormField>
					<FormField label="Services" name="services">
						<CheckBoxGroup
							name="services"
							options={['exterior wash', 'interior wash/vacuum', 'clay bar & polish']}
							onChange={(event) => setServices(event.value)}
						/>
					</FormField>
					<FormField label="Comments" name="comments">
						<TextArea
						name="comments"
						value={comments}
						onChange={(event) => setComments(event.target.value)}
						/>
					</FormField>
					<Box direction="row" justify="between" margin={{ top: 'medium' }}>
						<Button type="reset" label="Reset" />
						<Button type="submit" label="Submit" primary />
					</Box>
					</Form>
				</Box>
			</Box>
		</Layout>
	)
}

export default ContactPage
